import { ShapesElementProps } from "@/types/content-elements";

/**
 * Shape Waves for the section separator component.
 * TODO: When refactoring CSS/SVG the image is to be obtained from a central location.
 * @param {string} topColor hex code color which used for the top color
 * @param {string} bottomColor hex code color which used for the bottom color
 * @param {int} shapeHeight as px value how high the element should be
 * @param {boolean} shapeFlip should the shape be displayed inverted
 * @returns {JSX.Element}
 */
export default function ShapeWaves({
  topColor,
  bottomColor,
  shapeHeight,
  shapeFlip,
}: ShapesElementProps) {
  const transform = shapeFlip ? "rotateY(180deg)" : "unset";
  return (
    <>
      <div className="waves-container">
        <div className="custom-shape-divider-top-1695114533">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <style jsx>{`
          .waves-container {
            position: relative;
            background-color: ${shapeFlip ? topColor : bottomColor};
            top: 1px;
          }
          .custom-shape-divider-top-1695114533 {
            position: relative;
            top: -1px;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
          }

          .custom-shape-divider-top-1695114533 svg {
            position: relative;
            display: block;
            width: calc(100% + 1.3px);
            height: ${shapeHeight + 10}px;
            transform: ${transform};
          }

          .custom-shape-divider-top-1695114533 .shape-fill {
            fill: ${shapeFlip ? bottomColor : topColor};
          }
        `}</style>
      </div>
    </>
  );
}
